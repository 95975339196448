import { FC } from 'react';
import { AppShell, Header, Group, Title } from '@mantine/core';
import Link from 'next/link';
import Image from 'next/image';

interface Props {
  children: React.ReactNode;
}

const AppHeader: FC<Props> = function ({ children }) {
  return (
    <AppShell
      padding="md"
      header={
        <Header height={60}>
          <Group sx={{ height: '100%' }} px={20} position="left">
            <Link href="/" passHref>
              <Image
                src="/big-t.png"
                alt="UTK Tickle Logo"
                width={40}
                height={40}
              />
            </Link>
            <Title order={3}>EF Scheduler</Title>
          </Group>
        </Header>
      }
      sx={(theme) => ({
        paddingBottom: 100,
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[8]
            : theme.colors.gray[0],
      })}
    >
      {children}
    </AppShell>
  );
};

export default AppHeader;
