// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { Integrations as TracingIntegrations } from '@sentry/tracing';

const SENTRY_DSN: string = process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DS ?? '';

Sentry.init({
  dsn:
    SENTRY_DSN
    || 'https://7a4e13cef5ec40b0a6574d53af7339e6@o1082792.ingest.sentry.io/6093806',

  integrations: [new TracingIntegrations.BrowserTracing()],

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
